import { Module } from "vuex";
import {
  RootState,
  Applicant,
  ApplicantCreateBody,
  ApplicantUpdateBody,
  Service,
} from "@/store";
import { ApplicantService } from "@/services/interfaces";
import Vue from "vue";
import { NotificationActions } from "@/store/notification";
// DO NOT MOVE THIS OR ELSE
import { AlertStatus } from "@/store/interfaces";
import { APPLICANT_STATUS } from "@/store/constants";

export interface ApplicantState {
  loading: boolean;
  applicants: Applicant[];
  total: number;
  selected?: Applicant;
}

export enum ApplicantActions {
  CREATE = "ApplicantModule/create",
  UPDATE = "ApplicantModule/update",
  LIST = "ApplicantModule/list",
  SELECT = "ApplicantModule/select",
  DELETE = "ApplicantModule/deleteApplicant",
  REACTIVATE = "ApplicantModule/reactivate",
  GET_FILE = "ApplicantModule/getFile",
}

export enum ApplicantMutations {
  SET_LOADING = "ApplicantModule/setLoading",
  ADD_USER = "ApplicantModule/addApplicant",
  SET_APPLICANTS = "ApplicantModule/setApplicants",
  SET_TOTAL = "ApplicantModule/setTotal",
  SET_SELECTED = "ApplicantModule/setSelected",
}

export enum ApplicantGetters {
  LOADING = "ApplicantModule/loading",
  APPLICANTS = "ApplicantModule/applicants",
  TOTAL = "ApplicantModule/total",
  TOTAL_ACTIVE = "ApplicantModule/totalActive",
  TOTAL_APPLICANTS_IN_PROCESS = "ApplicantModule/totalApplicantsInProcess",
  SELECTED = "ApplicantModule/selected",
  RECENTLY_MODIFIED = "ApplicantModule/recentlyModified",
  SERVICES_MILITARY_STRING = "ApplicantModule/servicesMilitaryString",
  SERVICES_RESPONDER_STRING = "ApplicantModule/servicesResponderString",
}

export const ApplicantModule: Module<ApplicantState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    applicants: [],
    total: 0,
  },
  actions: {
    async create(
      { commit, dispatch },
      payload: { body: ApplicantCreateBody; service: ApplicantService }
    ) {
      try {
        commit("setLoading", true);
        const { body, service } = payload;
        const applicant = await service.createApplicant(body);
        commit("addApplicant", applicant);
        commit("setLoading", false);
        dispatch(
          NotificationActions.ALERT,
          {
            status: AlertStatus.SUCCESS,
            message: "Successfully Created Applicant",
          },
          { root: true }
        );
        return applicant;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
    async update(
      { state, commit, dispatch },
      payload: {
        id: string;
        body: ApplicantUpdateBody;
        service: ApplicantService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { id, body, service } = payload;
        const applicant = await service.updateApplicant(id, body);
        commit("addApplicant", applicant);
        if (state && state.selected && state.selected.id === applicant.id) {
          commit("setSelected", applicant);
        }
        commit("setLoading", false);
        dispatch(
          NotificationActions.ALERT,
          {
            status: AlertStatus.SUCCESS,
            message: "Successfully Updated Applicant",
          },
          { root: true }
        );
        return applicant;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
    async list(
      { commit, dispatch },
      payload: { service: ApplicantService; take?: number; skip?: number }
    ) {
      try {
        commit("setLoading", true);
        const { service, take, skip } = payload;
        const { applicants, total } = await service.listApplicants(
          take || 0, // defaulting to 0 to get all results until we need to paginate the table results based on size
          skip || 0
        );
        commit("setApplicants", applicants);
        commit("setTotal", total);
        commit("setLoading", false);
        return applicants;
      } catch (error) {
        if (process.env.NODE_ENV == "test") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
    async select(
      { commit, dispatch },
      payload: { id: string | number; service: ApplicantService }
    ) {
      try {
        commit("setLoading", true);
        const { id, service } = payload;
        const userFromApi = await service.getApplicant(id);
        if (userFromApi) {
          commit("addApplicant", userFromApi);
          commit("setSelected", userFromApi);
        }
        commit("setLoading", false);
        return userFromApi;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
    async deleteApplicant(
      { commit, dispatch },
      payload: { service: ApplicantService; id: string }
    ) {
      commit("setLoading", true);
      const { service, id } = payload;
      try {
        await service.deleteApplicant(id);
        commit("setLoading", false);
        dispatch(
          NotificationActions.ALERT,
          {
            status: AlertStatus.SUCCESS,
            message: "Successfully Deleted Applicant",
          },
          { root: true }
        );
        return null;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
        return null;
      }
    },
    async reactivate(
      { commit, dispatch },
      payload: { service: ApplicantService; id: string }
    ) {
      commit("setLoading", true);
      const { service, id } = payload;
      try {
        await service.reactivateApplicant(id);
        commit("setLoading", false);
        dispatch(
          NotificationActions.ALERT,
          {
            status: AlertStatus.SUCCESS,
            message: "Successfully Reactivated Applicant",
          },
          { root: true }
        );
        return null;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
        return null;
      }
    },

    async getFile(
      { commit, dispatch },
      payload: { service: ApplicantService; id: number }
    ) {
      commit("setLoading", true);
      const { id, service } = payload;
      try {
        const url = await service.getAppplicantFile(id);
        if (url) {
          commit("setLoading", false);
          return url;
        }

        commit("setLoading", false);
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
        return null;
      }
    },
  },
  mutations: {
    setLoading(state: ApplicantState, loading: boolean) {
      Vue.set(state, "loading", loading);
    },
    addApplicant(state: ApplicantState, applicant: Applicant) {
      const applicants = state.applicants.filter(
        (existingApplicant) => existingApplicant.id !== applicant.id
      );
      applicants.push(applicant);
      Vue.set(state, "applicants", applicants);
    },
    setApplicants(state: ApplicantState, applicants: Array<Applicant>) {
      Vue.set(state, "applicants", applicants);
    },
    setTotal(state: ApplicantState, total: number) {
      Vue.set(state, "total", total);
    },
    setSelected(state: ApplicantState, applicant: Applicant) {
      Vue.set(state, "selected", applicant);
    },
  },
  getters: {
    loading: (state) => state.loading,
    applicants: (state) => state.applicants,
    total: (state) => state.total,
    selected: (state) => state.selected,
    recentlyModified: (state) => {
      const sortedByLastModifiedDate: Applicant[] = state.applicants.sort(
        (a, b) => {
          const modifiedDateA = a.updateDate
            ? new Date(a.updateDate).getTime()
            : new Date(a.createDate).getTime();
          const modifiedDateB = b.updateDate
            ? new Date(b.updateDate).getTime()
            : new Date(b.createDate).getTime();
          if (modifiedDateA < modifiedDateB) {
            return 1;
          }
          if (modifiedDateA > modifiedDateB) {
            return -1;
          }
          return 0;
        }
      );
      return sortedByLastModifiedDate.slice(0, 5);
    },
    servicesMilitaryString: (state) => {
      let servicesString = "";
      if (state.selected && state.selected.services) {
        const servicesLength: number =
          (state.selected.services as Service[]).filter(
            (service: Service) => service.type === "military"
          ).length - 1;
        (state.selected.services as Service[])
          .filter((service: Service) => service.type === "military")
          .forEach((service: Service, index: number) => {
            if (service.type === "military") {
              if (index !== servicesLength) {
                servicesString += `${service.name}, `;
              } else {
                servicesString += `${service.name}`;
              }
            }
          });
      }
      return servicesString;
    },
    servicesResponderString: (state) => {
      let servicesString = "";
      if (state.selected && state.selected.services) {
        const servicesLength: number =
          (state.selected.services as Service[]).filter(
            (service: Service) => service.type === "responder"
          ).length - 1;
        (state.selected.services as Service[])
          .filter((service: Service) => service.type === "responder")
          .forEach((service: Service, index: number) => {
            if (service.type === "responder") {
              if (index !== servicesLength) {
                servicesString += `${service.name}, `;
              } else {
                servicesString += `${service.name}`;
              }
            }
          });
      }
      return servicesString;
    },
    totalApplicantsInProcess: (state) => {
      const filteredByInProcess: Applicant[] = state.applicants.filter(
        (applicant) =>
          applicant.deleted === null &&
          applicant.status !== APPLICANT_STATUS.APPROVAL &&
          applicant.status !== APPLICANT_STATUS.REJECTION
      );
      return (filteredByInProcess || []).length;
    },
    totalActive: (state) => {
      const filteredByActive: Applicant[] = state.applicants.filter(
        (applicant) => applicant.deleted === null
      );
      return (filteredByActive || []).length;
    },
  },
};

import { Module } from "vuex";
import { RootState, User } from "@/store";
// DO NOT MOVE THIS OR ELSE
import { AlertStatus } from "@/store/interfaces";
import { UserService } from "@/services/interfaces";
import Vue from "vue";
import { NotificationActions } from "@/store/notification";
import { USER_ROLES_DISPLAY_TEXT } from "@/store/constants";
import { Auth } from "aws-amplify";

export interface SessionState {
  authenticated: boolean;
  loading: boolean;
  user?: User;
}

export enum SessionActions {
  LOAD_USER = "SessionModule/loadUser",
  SIGN_OUT = "SessionModule/signOut",
}

export enum SessionMutations {
  SET_LOADING = "SessionModule/setLoading",
  SET_AUTHENTICATED = "SessionModule/setAuthenticated",
  SET_USER = "SessionModule/setUser",
}

export enum SessionGetters {
  AUTHENTICATED = "SessionModule/authenticated",
  LOADING = "SessionModule/loading",
  ROLE = "SessionModule/role",
  USER = "SessionModule/user",
  IS_SITE_ADMIN = "SessionModule/isSiteAdmin",
  IS_SITE_ADMIN_OR_ORGANIZATION_ADMIN = "SessionModule/isSiteOrOrganizationAdmin",
  IS_STAFF_OR_HIGHER = "SessionModule/isStaffOrHigher",
  IS_DOG_TRAINER = "SessionModule/isDogTrainer",
}

export const SessionModule: Module<SessionState, RootState> = {
  namespaced: true,
  state: {
    authenticated: false,
    loading: true,
  },
  actions: {
    async loadUser(
      { commit, dispatch },
      payload: {
        service: UserService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const userInfo = await Auth.currentUserInfo();
        if (userInfo && userInfo.attributes) {
          const userFromApi = await service.getUser(
            userInfo.id || userInfo.attributes.sub
          );
          commit("setUser", userFromApi);
          commit("setAuthenticated", true);
        } else {
          await dispatch("signOut");
        }
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        await dispatch("signOut");
      }
      commit("setLoading", false);
    },
    async signOut({ commit, dispatch }) {
      commit("setLoading", true);
      try {
        await Auth.signOut();
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
      }
      commit("setUser", undefined);
      commit("setAuthenticated", false);
      window.localStorage.clear();
      commit("setLoading", false);
    },
  },
  mutations: {
    setLoading(state: SessionState, loading: boolean) {
      Vue.set(state, "loading", loading);
    },
    setAuthenticated(state: SessionState, authenticated: boolean) {
      Vue.set(state, "authenticated", authenticated);
    },
    setUser(state: SessionState, user?: User) {
      Vue.set(state, "user", user);
    },
  },
  getters: {
    authenticated: (state) => state.authenticated,
    loading: (state) => state.loading,
    user: (state) => state.user,
    role: (state) =>
      state.user && state.user.role ? state.user.role.name.toLowerCase() : "",
    isSiteAdmin: (state) =>
      state.user && state.user.role
        ? state.user.role.name.toLowerCase() === "site admin"
        : false,
    isSiteOrOrganizationAdmin: (state) =>
      state.user && state.user.role
        ? ["site admin", "organization admin"].includes(
            state.user.role.name.toLowerCase()
          )
        : false,
    isStaffOrHigher: (state) =>
      state.user && state.user.role
        ? ["site admin", "organization admin", "staff"].includes(
            state.user.role.name.toLowerCase()
          )
        : false,
    isDogTrainer: (state) =>
      state.user && state.user.role
        ? state.user.isDogTrainer ||
          USER_ROLES_DISPLAY_TEXT.DOG_TRAINER.toLowerCase() ===
            state.user.role.name.toLowerCase()
        : false,
  },
};

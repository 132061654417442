import { Module } from "vuex";
import { RootState, Provider } from "@/store";
import { ReadOnlyEntityService } from "@/services/interfaces";
import Vue from "vue";
import { ProviderService } from "../../services/ProviderService";

export interface ProviderState {
  loading: boolean;
  providers: Array<Provider>;
}

export enum ProviderActions {
  LIST = "ProviderModule/list",
}

export enum ProviderMutations {
  SET_LOADING = "ProviderModule/setLoading",
  SET_PROVIDERS = "ProviderModule/setProviders",
}

export enum ProviderGetters {
  LOADING = "ProviderModule/loading",
  PROVIDERS = "ProviderModule/providers",
}

export const ProviderModule: Module<ProviderState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    providers: [],
  },
  actions: {
    async list(
      { commit, state },
      payload: {
        service: ReadOnlyEntityService<ProviderService>;
        force?: boolean;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service, force } = payload;
        if (state.providers.length < 1 || force === true) {
          const { entities } = await service.list();
          commit("setProviders", entities);
        }
        commit("setLoading", false);
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        commit("setLoading", false);
      }
    },
  },
  mutations: {
    setLoading(state: ProviderState, loading: boolean) {
      Vue.set(state, "loading", loading);
    },
    setProviders(state: ProviderState, providers: Array<Provider>) {
      Vue.set(state, "providers", providers);
    },
  },
  getters: {
    loading: (state) => state.loading,
    providers: (state) => state.providers,
  },
};

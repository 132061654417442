import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import compositionApi from "@vue/composition-api";
import Amplify, * as AmplifyModules from "aws-amplify";
import "@aws-amplify/ui-vue";
import VueTheMask from "vue-the-mask";
import { AmplifyPlugin } from "aws-amplify-vue";
import { sync } from "vuex-router-sync";
import moment from "moment";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_WEB_APP_CLIENT_ID,
    mandatorySignIn: true,
  },
});

Vue.use(VueTheMask);

Vue.use(AmplifyPlugin, AmplifyModules);

Vue.use(compositionApi);

Vue.config.productionTip = false;

sync(store, router);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.filter("formatDate", (value: Date | string) => {
  if (value) {
    return moment(String(value)).format("MM/DD/YYYY");
  }
});

import { ReadOnlyEntityService, PaginatedResponse } from "./interfaces";
import { Service } from "@/store";
import { AxiosInstance } from "axios";

export class ServiceTypeService implements ReadOnlyEntityService<Service> {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  public async list(): Promise<PaginatedResponse<Service>> {
    const response = await this.http.get(
      `${this.apiUrl}/applicantservicetypes`
    );
    return {
      entities: response.data.entities,
      total: response.data.total,
    };
  }

  get(id: number): Promise<Service> {
    throw new Error("Method not implemented." + id);
  }
}

import { RouteConfig } from "vue-router";

export const applicantRoutes: RouteConfig[] = [
  {
    path: "/applicants/create",
    name: "ApplicantCreate",
    meta: {
      title: "Create Applicant",
    },
    component: () =>
      import(
        /* webpackChunkName: "applicant-create" */ "@/views/applicants/Create.vue"
      ),
  },
  {
    path: "/applicants",
    name: "ApplicantList",
    meta: {
      title: "Applicants",
    },
    component: () =>
      import(
        /* webpackChunkName: "applicant-list" */ "@/views/applicants/List.vue"
      ),
  },
  {
    path: "/applicants/:id",
    name: "ApplicantUpdate",
    meta: {
      title: "Update Applicant",
    },
    component: () =>
      import(
        /* webpackChunkName: "applicant-update" */ "@/views/applicants/Update.vue"
      ),
  },
  {
    path: "/applicants/details/:id",
    name: "ApplicantDetail",
    meta: {
      title: "Applicant Detail",
    },
    component: () =>
      import(
        /* webpackChunkName: "applicant-detail" */ "@/views/applicants/Details.vue"
      ),
  },
];

import { DogService as IDogService } from "./interfaces";
import { Dog, DogCreateBody, DogTrainingCreateBody } from "@/store";
import { AxiosInstance } from "axios";
import { DogUpdateBody } from "@/store/interfaces";
import { DOG_ATTACHMENT_TYPES } from "@/store/constants";

export class DogService implements IDogService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getDog(id: string): Promise<Dog> {
    const response = await this.http.get(`${this.apiUrl}/dogs/${id}`);
    return response.data.entity;
  }

  async createDog(body: DogCreateBody): Promise<Dog> {
    if (body.veterinarian) {
      body.veterinarian = JSON.stringify(body.veterinarian);
    }
    if (body.notes) {
      body.notes = JSON.stringify(body.notes);
    }
    if (body.medical) {
      body.medical = JSON.stringify(body.medical);
    }
    const form = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (
        key !== "acquisitionFiles" &&
        key !== "medicalNotesFiles" &&
        key !== "medicalRecordsFiles"
      ) {
        form.append(key, value);
      }
    });

    if (body.acquisitionFiles) {
      body.acquisitionFiles.forEach((value) => {
        form.append(DOG_ATTACHMENT_TYPES.ACQUISITION, value);
      });
    }

    if (body.medicalRecordsFiles) {
      body.medicalRecordsFiles.forEach((value) => {
        form.append(DOG_ATTACHMENT_TYPES.MEDICAL_RECORDS, value);
      });
    }

    if (body.medicalNotesFiles) {
      body.medicalNotesFiles.forEach((value) => {
        form.append(DOG_ATTACHMENT_TYPES.MEDICAL_NOTES, value);
      });
    }

    const response = await this.http.post(`${this.apiUrl}/dogs`, form, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.entity;
  }

  async updateDog(id: number | string, body: DogUpdateBody): Promise<Dog> {
    if (body.notes) {
      body.notes = JSON.stringify(body.notes);
    }
    if (body.veterinarian) {
      body.veterinarian = JSON.stringify(body.veterinarian);
    }
    if (body.dogTrainings) {
      body.dogTrainings = JSON.stringify(body.dogTrainings);
    }
    if (body.deletedFiles) {
      body.deletedFiles = JSON.stringify(body.deletedFiles);
    }
    if (body.medical) {
      body.medical = JSON.stringify(body.medical);
    }
    const form = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (
        key !== "acquisitionFiles" &&
        key !== "medicalNotesFiles" &&
        key !== "medicalRecordsFiles"
      ) {
        form.append(key, value);
      }
    });

    if (body.acquisitionFiles) {
      body.acquisitionFiles.forEach((value) => {
        form.append(DOG_ATTACHMENT_TYPES.ACQUISITION, value);
      });
    }

    if (body.medicalRecordsFiles) {
      body.medicalRecordsFiles.forEach((value) => {
        form.append(DOG_ATTACHMENT_TYPES.MEDICAL_RECORDS, value);
      });
    }

    if (body.medicalNotesFiles) {
      body.medicalNotesFiles.forEach((value) => {
        form.append(DOG_ATTACHMENT_TYPES.MEDICAL_NOTES, value);
      });
    }

    const response = await this.http.patch(`${this.apiUrl}/dogs/${id}`, form, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.entity;
  }

  async createDogTraining(
    id: number | string,
    body: DogTrainingCreateBody
  ): Promise<Dog> {
    const response = await this.http.patch(
      `${this.apiUrl}/dogs/${id}/training`,
      body
    );
    return response.data.entity;
  }

  async deleteTraining(
    id: number | string,
    trainingId: number | string
  ): Promise<Dog> {
    const response = await this.http.delete(
      `${this.apiUrl}/dogs/${id}/training/${trainingId}`
    );
    return response.data.entity;
  }

  async listDogs(
    take: number,
    skip: number
  ): Promise<{ dogs: Dog[]; total: number }> {
    const response = await this.http.get(`${this.apiUrl}/dogs`, {
      params: {
        take,
        skip,
      },
    });
    return {
      dogs: response.data.entities,
      total: response.data.total,
    };
  }

  async deleteDog(id: number | string): Promise<number> {
    const response = await this.http.delete(`${this.apiUrl}/dogs/${id}`);
    return response.status;
  }

  async reactivateDog(id: string): Promise<number> {
    const response = await this.http.patch(
      `${this.apiUrl}/dogs/${id}/reactivate`
    );
    return response.status;
  }

  async getDogFile(id: number): Promise<string> {
    const response = await this.http.get(`${this.apiUrl}/files/dog/${id}`);
    return response.data.entity;
  }
}

import { Store } from "vuex";
import { RootState } from "@/store";
import { computed } from "@vue/composition-api";
import {
  InterfaceModuleGetters,
  InterfaceModuleActions,
} from "@/store/interface";

export function useInterfaceNavigation(store: Store<RootState>) {
  const drawer = computed<boolean>(
    () => store.getters[InterfaceModuleGetters.DRAWER]
  );

  function toggleDrawer() {
    store.dispatch(InterfaceModuleActions.TOGGLE);
  }

  return {
    drawer,
    toggleDrawer,
  };
}

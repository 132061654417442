import { Module } from "vuex";
import { RootState, Role } from "@/store";
// DO NOT MOVE THIS OR ELSE
import { AlertStatus } from "@/store/interfaces";
import { ReadOnlyEntityService } from "@/services/interfaces";
import Vue from "vue";
import { NotificationActions } from "@/store/notification";

export interface RoleState {
  loading: boolean;
  roles: Array<Role>;
}

export enum RoleActions {
  LIST = "RoleModule/list",
}

export enum RoleMutations {
  SET_LOADING = "RoleModule/setLoading",
  SET_ROLES = "RoleModule/setRoles",
}

export enum RoleGetters {
  LOADING = "RoleModule/loading",
  ROLES = "RoleModule/roles",
}

export const RoleModule: Module<RoleState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    roles: [],
  },
  actions: {
    async list(
      { commit, state, dispatch },
      payload: { service: ReadOnlyEntityService<Role>; force?: boolean }
    ) {
      try {
        commit("setLoading", true);
        const { service, force } = payload;
        if (state.roles.length < 1 || force === true) {
          const { entities } = await service.list();
          commit("setRoles", entities);
        }
        commit("setLoading", false);
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
  },
  mutations: {
    setLoading(state: RoleState, loading: boolean) {
      Vue.set(state, "loading", loading);
    },
    setRoles(state: RoleState, roles: Array<Role>) {
      Vue.set(state, "roles", roles);
    },
  },
  getters: {
    loading: (state) => state.loading,
    roles: (state) => state.roles,
  },
};

import { ReadOnlyEntityService } from "@/services/interfaces";
import { Role } from "@/store";
import { provide, inject } from "@vue/composition-api";

export const roleServiceSymbol = Symbol();

export function provideRoleService(service: ReadOnlyEntityService<Role>) {
  return provide(roleServiceSymbol, service);
}

export function useRoleService(): ReadOnlyEntityService<Role> {
  const service = inject<ReadOnlyEntityService<Role>>(roleServiceSymbol);
  if (!service) {
    throw Error("No Role Service to Inject!");
  }
  return service;
}

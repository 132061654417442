import { DogTrainingService as IDogTrainingService } from "./interfaces";
import { DogTraining, DogTrainingCreateBody } from "@/store";
import { AxiosInstance } from "axios";

export class DogTrainingService implements IDogTrainingService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async createDogTraining(body: DogTrainingCreateBody): Promise<DogTraining> {
    const form = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      form.append(key, value);
    });
    const response = await this.http.post(`${this.apiUrl}/dog-training`, form, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.entity;
  }
}

import { ApplicantService } from "@/services/interfaces";
import { provide, inject } from "@vue/composition-api";

export const applicantServiceSymbol = Symbol();

export function provideApplicantService(store: ApplicantService) {
  return provide(applicantServiceSymbol, store);
}

export function useApplicantService() {
  const applicantService = inject<ApplicantService>(applicantServiceSymbol);
  if (!applicantService) {
    throw Error("No Applicant Service to Inject!!");
  }
  return applicantService;
}

import { Module } from "vuex";
import { RootState, User } from "@/store";
import { UserService } from "@/services/interfaces";
import Vue from "vue";
import { NotificationActions } from "@/store/notification";
// DO NOT MOVE THIS OR ELSE
import {
  AlertStatus,
  UserCreateBody,
  UserUpdateBody,
} from "@/store/interfaces";
import { USER_ROLES_DISPLAY_TEXT } from "@/store/constants";

export interface UserState {
  loading: boolean;
  users: User[];
  total: number;
  selected?: User;
}

export enum UserActions {
  CREATE = "UserModule/create",
  LIST = "UserModule/list",
  UPDATE = "UserModule/update",
  DELETE = "UserModule/deleteUser",
  SELECT = "UserModule/select",
  REACTIVATE = "UserModule/reactivate",
  RESEND_CREDENTIALS = "UserModule/resendCredentials",
}

export enum UserMutations {
  SET_LOADING = "UserModule/setLoading",
  ADD_USER = "UserModule/addUser",
  SET_USERS = "UserModule/setUsers",
  SET_TOTAL = "UserModule/setTotal",
  SET_SELECTED = "UserModule/setSelected",
}

export enum UserGetters {
  LOADING = "UserModule/loading",
  USERS = "UserModule/users",
  DOG_TRAINERS = "UserModule/dogTrainers",
  TOTAL = "UserModule/total",
  SELECTED = "UserModule/selected",
  SELECTED_USER_ROLE = "UserModule/selectedUserRole",
}

export const UserModule: Module<UserState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    users: [],
    total: 0,
  },
  actions: {
    async create(
      { commit, dispatch },
      payload: { body: UserCreateBody; service: UserService }
    ) {
      try {
        commit("setLoading", true);
        const { body, service } = payload;
        const user = await service.createUser(body);
        commit("addUser", user);
        commit("setLoading", false);
        dispatch(
          NotificationActions.ALERT,
          {
            status: AlertStatus.SUCCESS,
            message: "Successfully Created User",
          },
          { root: true }
        );
        return user;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
    async list(
      { commit, dispatch },
      payload: { service: UserService; take?: number; skip?: number }
    ) {
      try {
        commit("setLoading", true);
        const { service, take, skip } = payload;
        const { users, total } = await service.listUsers(
          take || 0, // defaulting to 0 to get all results until we need to paginate the table results based on size
          skip || 0
        );
        commit("setUsers", users);
        commit("setTotal", total);
        commit("setLoading", false);
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
    async update(
      { state, commit, dispatch },
      payload: { id: string; body: UserUpdateBody; service: UserService }
    ) {
      try {
        commit("setLoading", true);
        const { id, body, service } = payload;
        const user = await service.updateUser(id, body);
        commit("addUser", user);
        if (state && state.selected && state.selected.id === user.id) {
          commit("setSelected", user);
        }
        commit("setLoading", false);
        dispatch(
          NotificationActions.ALERT,
          {
            status: AlertStatus.SUCCESS,
            message: "Successfully Updated User",
          },
          { root: true }
        );
        return user;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
    async deleteUser(
      { commit, dispatch },
      payload: { service: UserService; id: string }
    ) {
      commit("setLoading", true);
      const { service, id } = payload;
      try {
        await service.deleteUser(id);
        commit("setLoading", false);
        dispatch(
          NotificationActions.ALERT,
          {
            status: AlertStatus.SUCCESS,
            message: "Successfully Deleted User",
          },
          { root: true }
        );
        return null;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
        return null;
      }
    },
    async reactivate(
      { commit, dispatch },
      payload: { service: UserService; id: string }
    ) {
      commit("setLoading", true);
      const { service, id } = payload;
      try {
        await service.reactivateUser(id);
        commit("setLoading", false);
        dispatch(
          NotificationActions.ALERT,
          {
            status: AlertStatus.SUCCESS,
            message: "Successfully Reactivated User",
          },
          { root: true }
        );
        return null;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
        return null;
      }
    },
    async select(
      { commit, dispatch },
      payload: { id: string; service: UserService }
    ) {
      try {
        commit("setLoading", true);
        const { id, service } = payload;
        const userFromApi = await service.getUser(id);
        if (userFromApi) {
          commit("addUser", userFromApi);
          commit("setSelected", userFromApi);
        }
        commit("setLoading", false);
        return userFromApi;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
    async resendCredentials(
      { commit, dispatch },
      payload: { id: string; service: UserService }
    ) {
      try {
        commit("setLoading", true);
        const { id, service } = payload;
        const success = await service.resendUserPassword(id);
        if (!success) {
          throw new Error("Unable to reset user's pasword.");
        }
        commit("setLoading", false);
        dispatch(
          NotificationActions.ALERT,
          {
            status: AlertStatus.SUCCESS,
            message: "Successfully Resent User Credentials",
          },
          { root: true }
        );
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
  },
  mutations: {
    setLoading(state: UserState, loading: boolean) {
      Vue.set(state, "loading", loading);
    },
    addUser(state: UserState, user: User) {
      const users = state.users.filter(
        (existingUser) => existingUser.id !== user.id
      );
      users.push(user);
      Vue.set(state, "users", users);
    },
    setUsers(state: UserState, users: Array<User>) {
      Vue.set(state, "users", users);
    },
    setTotal(state: UserState, total: number) {
      Vue.set(state, "total", total);
    },
    setSelected(state: UserState, user: User) {
      Vue.set(state, "selected", user);
    },
  },
  getters: {
    loading: (state) => state.loading,
    users: (state) => state.users,
    dogTrainers: (state) => {
      const dogTraininers = state.users.filter(
        (user) =>
          user.isDogTrainer ||
          USER_ROLES_DISPLAY_TEXT.DOG_TRAINER.toLowerCase() ===
            user.role.name.toLowerCase()
      );
      if (dogTraininers && dogTraininers.length) {
        return dogTraininers
          .filter((dogTrainer) => !dogTrainer.deleted)
          .sort((a, b) => {
            const aName =
              a.firstName.toLowerCase() + " " + a.lastName.toLowerCase();
            const bName =
              b.firstName.toLowerCase() + " " + b.lastName.toLowerCase();
            if (aName > bName) {
              return 1;
            }
            if (aName < bName) {
              return -1;
            }
            return 0;
          });
      } else return [];
    },
    total: (state) => state.total,
    selected: (state) => state.selected,
    selectedUserRole: (state) =>
      state.selected && state.selected.role
        ? state.selected.role.name.toLowerCase()
        : "",
  },
};

export interface UserCreateBody {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  role: number;
  isDogTrainer: boolean;
  cognitoDisabled?: boolean;
}

export interface UserUpdateBody {
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: number;
  isDogTrainer?: boolean;
}

export interface Role {
  id: number;
  name: string;
  users?: Array<User>;
}

export interface ApplicantReport {
  firstName: string;
  lastName: string;
  birthDate: Date | string;
  state: string;
  status: string;
  startDate: Date | string;
  homeVisitDate: Date | string;
  military: string;
  firstResponder: string;
  dog: string;
  created: Date | string;
}

export interface DogReport {
  name: string;
  birthDate: Date | string;
  acquisitionDate: Date | string;
  status: string;
  totalPublicMinutes: number | string;
  totalRegularMinutes: number | string;
  placementDate: Date | string;
  applicant: string;
  recertificationDate: Date | string;
  createDate: Date | string;
}

export interface Service {
  id: number;
  name: string;
  type: string;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  mfaEnabled: boolean;
  isDogTrainer: boolean;
  cognitoDisabled?: boolean;
  role: Role;
  deleted?: Date;
  dogTrainings?: DogTraining[] | null;
}

export interface Applicant {
  id: number;
  firstName: string;
  lastName: string;
  phone?: string;
  homePhone?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  stateOrTerritory: string;
  zipCode: string;
  birthDate: Date;
  email: string;
  photo?: string;
  startDate?: Date;
  homeVisitDate?: Date;
  deleted?: Date;
  displayPhoto?: string;
  services: Service[] | string;
  notes?: ApplicantNote[] | string;
  sponsor?: Sponsor;
  status?: number;
  createdBy: string;
  createDate: Date;
  updatedBy?: string;
  updateDate?: Date;
  provider?: Provider;
  emergencyContact?: ApplicantEmergencyContact;
  attachments?: ApplicantFile[];
  dog?: Dog;
  additionalProviders?: Provider[];
}

export interface ApplicantFile {
  id: number;
  name: string;
  location: string;
  type: string;
  createdBy: string;
  createDate: Date;
}

export interface DogFile {
  id: number;
  name: string;
  location: string;
  type: string;
  createdBy: string;
  createDate: Date;
}

export interface ApplicantCreateBody {
  firstName: string;
  lastName: string;
  phone?: string;
  homePhone?: string;
  birthDate: Date | string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  stateOrTerritory: string;
  zipCode: string;
  email: string;
  photo?: File;
  startDate?: Date | string;
  homeVisitDate?: Date | string;
  services: Service[] | string;
  notes?: ApplicantNote[] | string;
  sponsor?: Sponsor | string;
  status?: number;
  provider?: Provider | string;
  dog?: Dog;
  emergencyContact?: ApplicantEmergencyContact | string;
  serviceEmploymentFiles?: File[];
  medicalFiles?: File[];
  homeVisitFile?: File;
  applicationFile?: File;
  contractFile?: File;
  additionalProviders?: Provider[] | string;
}

export interface ApplicantUpdateBody {
  firstName?: string;
  lastName?: string;
  phone?: string | null;
  homePhone?: string | null;
  birthDate?: Date | string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateOrTerritory?: string;
  zipCode?: string;
  email?: string;
  photo?: File;
  startDate?: Date | string;
  homeVisitDate?: Date | string;
  services?: Service[] | string;
  notes?: ApplicantNote[] | string;
  sponsor?: Sponsor | string | null;
  provider?: Provider | string | null;
  status?: number;
  dog?: Dog;
  emergencyContact?: ApplicantEmergencyContact | string | null;
  deletedFiles?: number[] | string;
  serviceEmploymentFiles?: File[];
  medicalFiles?: File[];
  homeVisitFile?: File;
  applicationFile?: File;
  contractFile?: File;
  additionalProviders?: Provider[] | string | null;
}

export interface Provider {
  id: number | string;
  firstName: string;
  lastName: string;
  phone: string;
  specialty: number;
  email: string;
}

export interface Sponsor {
  id: number | string;
  firstName: string;
  lastName: string;
  phone: string;
  email?: string;
  relationship: number;
  delete?: boolean | null;
}

export interface Dog {
  id: number;
  name: string;
  breed: string;
  color: string;
  birthDate: Date | string;
  photo?: string;
  displayPhoto?: string;
  deleted?: Date;
  applicant: Applicant;
  placementDate: Date | string;
  adoptionDate: Date | string;
  dogTrainings?: DogTraining[];
  trainingStatus?: number;
  trainingCertExpires?: Date | string;
  microchipBrand?: string;
  microchipId?: string;
  veterinarian?: DogVeterinarian | null;
  medical?: DogMedical;
  notes?: DogNote[] | string;
  attachments?: DogFile[];
  acquisitionType?: number | null;
  acquiredDate?: Date | string;
  acquisitionFiles?: File[];
  medicalNotesFiles?: File[];
  medicalRecordsFiles?: File[];
  status?: string | null;
  totalTrainingTime?: number | null;
  createDate?: Date | string;
  retired?: boolean | null;
  deceased?: boolean | null;
}

export interface DogExt extends Dog {
  oldestVaccinationInDays?: number;
  oldestVaccinationInDaysAbsAndRnd?: number;
  trainingCertExpirationInDays?: number;
  trainingCertExpirationInDaysAbsAndRnd?: number;
}

export interface DogTrainerTableDisplayBody {
  id: number;
  dogId: number;
  name: string;
  date: Date | string;
  regularTraining: number | string;
  publicAccessTraining: number | string;
  user: User;
}

export interface DogCreateBody {
  name: string;
  breed: string;
  color: string;
  birthDate: Date | string;
  microchipBrand?: string;
  microchipId?: string;
  photo?: File;
  recipient?: number;
  placementDate?: Date | string;
  adoptionDate?: Date | string;
  veterinarian?: DogVeterinarian | string;
  medical?: DogMedicalCreateBody | string;
  notes?: DogNote[] | string;
  acquisitionType?: number | null;
  acquiredDate?: Date | string;
  acquisitionFiles?: File[];
  medicalNotesFiles?: File[];
  medicalRecordsFiles?: File[];
  retired?: boolean | null;
  deceased?: boolean | null;
}

export interface DogVeterinarian {
  id: number | string;
  firstName: string;
  lastName: string;
  practiceName: string;
  phone: string;
  email?: string;
  delete?: boolean | null;
}

export interface DogMedical {
  id: number | string;
  parvovirusDue?: Date | string;
  dhpDue?: Date | string;
  leptospirosisDue?: Date | string;
  bordetellaDue?: Date | string;
  rabiesDue?: Date | string;
  spayedNeutered?: Date | string;
  delete?: boolean | null;
}

export interface DogMedicalCreateBody {
  parvovirusDue?: Date | string | null;
  dhpDue?: Date | string | null;
  leptospirosisDue?: Date | string | null;
  bordetellaDue?: Date | string | null;
  rabiesDue?: Date | string | null;
  spayedNeutered?: Date | string | null;
  delete?: boolean | null;
}

export interface DogUpdateBody {
  name?: string;
  breed?: string;
  color?: string;
  birthDate?: Date | string;
  photo?: File;
  adoptionDate?: Date | string;
  placementDate?: Date | string;
  recipient?: number;
  dogTrainings?: DogTraining[] | string | null;
  trainingStatus?: number;
  trainingCertExpires?: Date | string;
  microchipBrand?: string;
  microchipId?: string;
  veterinarian?: DogVeterinarian | string | null;
  notes?: ApplicantNote[] | string;
  adoptionFile?: File | null;
  deletedFiles?: number[] | string;
  acquisitionType?: number | null;
  acquiredDate?: Date | string;
  acquisitionFiles?: File[] | null;
  medical?: DogMedical | string | null;
  medicalNotesFiles?: File[];
  medicalRecordsFiles?: File[];
  retired?: boolean | null;
  deceased?: boolean | null;
}

export interface DogTraining {
  id: number;
  dogId: number;
  date: Date | string;
  regularTraining: number | string;
  publicAccessTraining: number | string;
  user: User;
}

export interface DogTrainingCreateBody {
  date: Date | string;
  regularTraining: number;
  publicAccessTraining: number;
  user: string | User;
}

export interface DogNote {
  id: number;
  text: string;
  createdBy: string;
  createDate: Date;
  updatedBy?: string;
  updateDate: Date;
}

export interface DogNoteCreateBody {
  text: string;
  createdBy: string;
  createDate: Date;
}

export interface DogNoteUpdateBody {
  id: number;
  text: string;
  createdBy: string;
  createDate: Date;
  updatedBy: string;
  updateDate: Date;
}

export interface ApplicantNote {
  id: number;
  text: string;
  createdBy: string;
  createDate: Date;
  updatedBy?: string;
  updateDate: Date;
}

export interface ApplicantNoteCreateBody {
  text: string;
  createdBy: string;
  createDate: Date;
}

export interface ApplicantNoteUpdateBody {
  id: number;
  text: string;
  createdBy: string;
  createDate: Date;
  updatedBy: string;
  updateDate: Date;
}
export interface ApplicantEmergencyContact {
  id: number | string;
  firstName: string;
  lastName: string;
  relationship: number;
  phone: string;
  email?: string;
  delete?: boolean | null;
}

export type RuleFunction<T> = (arg: T) => boolean | string;

export enum AlertStatus {
  INFO = "blue",
  WARNING = "orange",
  ERROR = "red",
  SUCCESS = "green",
}

export interface Alert {
  status: AlertStatus;
  message: string;
}

export interface ApplicantAttachments {
  applicationFile?: ApplicantFile;
  homeVisitFile?: ApplicantFile;
  medicalFiles?: ApplicantFile[];
  serviceEmploymentFiles?: ApplicantFile[];
}

import { Module } from "vuex";
import { RootState } from "@/store";

export interface InterfaceState {
  drawer: boolean;
}

export enum InterfaceModuleActions {
  TOGGLE = "InterfaceModule/toggleDrawer",
}

export enum InterfaceModuleMutations {
  CLOSE = "InterfaceModule/closeDrawer",
  OPEN = "InterfaceModule/openDrawer",
}

export enum InterfaceModuleGetters {
  DRAWER = "InterfaceModule/drawer",
}

export const InterfaceModule: Module<InterfaceState, RootState> = {
  namespaced: true,
  state: {
    drawer: true,
  },
  actions: {
    toggleDrawer({ commit, state }) {
      if (state.drawer) {
        commit("closeDrawer");
      } else {
        commit("openDrawer");
      }
    },
  },
  mutations: {
    closeDrawer(state) {
      state.drawer = false;
    },
    openDrawer(state) {
      state.drawer = true;
    },
  },
  getters: {
    drawer: (state) => state.drawer,
  },
};
















































import { defineComponent, computed, ref, watch } from "@vue/composition-api";
import store, { RootGetters, Alert, User } from "@/store";
import {
  provideStore,
  provideUserService,
  provideRoleService,
  provideApplicantService,
  provideDogService,
  provideRouter,
  provideServiceTypeService,
  provideProviderService,
  provideDogTrainingService,
} from "@/composables/providers";
import { Hub } from "aws-amplify";
import { SessionGetters, SessionActions } from "@/store/session";
import { UserService } from "@/services/UserService";
import { RoleService } from "@/services/RoleService";
import { ApplicantService } from "@/services/ApplicantService";
import axios from "@/axios";
import router from "@/router";
import Navigation from "@/components/layout/Navigation.vue";
import { DogService } from "@/services/DogService";
import { DogTrainingService } from "@/services/DogTrainingService";
import { ServiceTypeService } from "./services/ServiceTypeService";
import { NotificationActions, NotificationGetters } from "@/store/notification";
import { ProviderService } from "@/services/ProviderService";
import { Route } from "vue-router/types/router";

export default defineComponent({
  name: "App",
  components: { Navigation },
  setup() {
    // Dependency Injection
    provideStore(store);
    provideUserService(new UserService(axios));
    provideRoleService(new RoleService(axios));
    provideApplicantService(new ApplicantService(axios));
    provideDogService(new DogService(axios));
    provideDogTrainingService(new DogTrainingService(axios));
    provideServiceTypeService(new ServiceTypeService(axios));
    provideProviderService(new ProviderService(axios));
    provideRouter(router);
    const authenticated = computed<boolean>(
      () => store.getters[SessionGetters.AUTHENTICATED]
    );
    const sessionLoading = computed<boolean>(
      () => store.getters[SessionGetters.LOADING]
    );
    const appLoading = computed<boolean>(
      () => store.getters[RootGetters.LOADING]
    );
    const loading = computed<boolean>(
      () => appLoading.value || sessionLoading.value
    );

    async function redirect(currentRoute: Route) {
      if (
        currentRoute.query.redirect &&
        currentRoute.query.redirect !== router.currentRoute.path
      ) {
        await router.push(currentRoute.query.redirect?.toString() || "/");
      } else if (router.currentRoute.path !== currentRoute.path) {
        await router.push(currentRoute.path || "/");
      }
    }

    Hub.listen("auth", async ({ payload: { event } }) => {
      switch (event) {
        case "signIn": {
          if (!store.getters[SessionGetters.LOADING]) {
            await store.dispatch(SessionActions.LOAD_USER, {
              service: new UserService(axios),
            });
          }
          await redirect(router.currentRoute);
          break;
        }
        case "signOut": {
          //redirect user to home page with redirect if they get signed out
          const currentPath = router?.currentRoute?.path;
          if (currentPath && currentPath !== "/") {
            await router.push({
              name: "Dashboard",
              query: { redirect: currentPath },
            });
          }
          break;
        }
      }
    });

    // eslint-disable-next-line
    const configurationOptions = ref<{
      signInConfig: { isSignUpDisplayed: boolean };
    }>({
      signInConfig: {
        isSignUpDisplayed: false,
      },
    });

    const alert = ref<boolean>(false);
    const latestAlert = computed<Alert | null>(
      () => store.getters[NotificationGetters.LATEST_ALERT]
    );

    watch(latestAlert, (current) => {
      if (current) {
        alert.value = true;
      }
    });

    const user = computed<User | null>(
      () => store.getters[SessionGetters.USER]
    );

    watch(
      user,
      (current) => {
        if (!current && !store.getters[SessionGetters.LOADING]) {
          store.dispatch(SessionActions.LOAD_USER, {
            service: new UserService(axios),
          });
        }
      },
      { immediate: true }
    );

    watch(alert, (current, previous) => {
      if (previous && !current) {
        store.dispatch(NotificationActions.CLEAR_ALERT, latestAlert.value);
      }
    });

    return {
      store,
      authenticated,
      sessionLoading,
      appLoading,
      loading,
      configurationOptions,
      alert,
      latestAlert,
    };
  },
});

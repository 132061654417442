import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { Auth } from "aws-amplify";

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}`,
});

const requestHandler = async (request: AxiosRequestConfig) => {
  await Auth.currentSession()
    .then(async (userSession) => {
      if (userSession) {
        const jwtToken = userSession.getIdToken().getJwtToken();
        request.headers.Authorization = `Bearer ${jwtToken}`;
      }
    })
    .catch(async (error) => {
      if (process.env.NODE_ENV == "development") {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });

  return request;
};

const responseErrorHandler = async (error: AxiosError) => {
  let errMessage;
  if (error && error.response && error.response.status) {
    switch (error.response.status as number) {
      case 401:
        errMessage = {
          ...error,
          data:
            "Unauthorized - please re-authenticate to try your request again.",
        };
        await Auth.signOut();
        break;
      case 500:
        errMessage = {
          ...error,
          data:
            "Something went wrong - please wait and try your request again later.  If the problem persists, please contact your IT support team.",
        };
        break;
      default:
        if (error && error.response && error.response.data) {
          errMessage = error.response;
        } else if (error && error.message) {
          errMessage = { ...error, data: error.message };
        } else {
          errMessage = { ...error, data: "Unknown error has occurred" };
        }
        break;
    }
  } else {
    // Shouldn't really get here, but just in case
    errMessage = {
      ...error,
      data: "Unknown error has occurred with no status code.",
    };
  }
  return Promise.reject(errMessage);
};
axiosInstance.defaults.timeout = 60000;
axiosInstance.interceptors.request.use((request) => requestHandler(request));
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => responseErrorHandler(error)
);

export default axiosInstance;

import { Module } from "vuex";
import {
  RootState,
  Dog,
  DogCreateBody,
  DogUpdateBody,
  DogTrainingCreateBody,
  DogExt,
} from "@/store";
import { DogService, UserService } from "@/services/interfaces";
import Vue from "vue";
import { NotificationActions } from "@/store/notification";
// DO NOT MOVE THIS OR ELSE
import { AlertStatus, User } from "@/store/interfaces";
import { DogTrainerTableDisplayBody } from "../interfaces";
import { DOG_TRAINING_STATUS_VALUES } from "@/store/constants";
import moment from "moment";

export interface DogState {
  loading: boolean;
  dogs: Dog[];
  total: number;
  selected?: Dog;
  currentTrainer?: User;
}

export enum DogActions {
  CREATE = "DogModule/create",
  LIST = "DogModule/list",
  SELECT = "DogModule/select",
  DELETE = "DogModule/deleteDog",
  REACTIVATE = "DogModule/reactivate",
  UPDATE = "DogModule/update",
  ADD_TRAINING = "DogModule/addTraining",
  SELECT_CURRENT_TRAINER = "DogModule/selectCurrentTrainer",
  GET_FILE = "DogModule/getFile",
  DELETE_TRAINING = "DogModule/deleteTraining",
}

export enum DogMutations {
  SET_LOADING = "DogModule/setLoading",
  ADD_DOG = "DogModule/addDog",
  SET_DOGS = "DogModule/setDogs",
  SET_TOTAL = "DogModule/setTotal",
  SET_SELECTED = "DogModule/setSelected",
  SET_CURRENT_TRAINER = "DogModule/setCurrentTrainer",
}

export enum DogGetters {
  LOADING = "DogModule/loading",
  DOGS = "DogModule/dogs",
  DOGS_SORTED_BY_NAME = "DogModule/dogsSortedByName",
  TOTAL = "DogModule/total",
  TOTAL_ACTIVE = "DogModule/totalActive",
  TOTAL_DOGS_NEEDING_RECERTIFICATIONS = "DogModule/totalDogsNeedingRecertifications",
  TOTAL_DOGS_NEEDING_VACCINATIONS = "DogModule/totalDogsNeedingVaccinations",
  TOTAL_DOGS_IN_TRAINING = "DogModule/totalDogsInTraining",
  SELECTED = "DogModule/selected",
  CURRENT_TRAINER_TRAININGS = "DogModule/currentTrainerTrainings",
  CURRENT_TRAINER = "DogModule/currentTrainer",
  RECERTIFICATION_NEEDED = "DogModule/recertificationNeeded",
  VACCINATION_NEEDED = "DogModule/vaccinationNeeded",
}

function getDate(date: string) {
  if (date) {
    return moment(new Date(date).toISOString().substr(0, 10)).format(
      "MM/DD/YYYY"
    );
  }
}

export const DogModule: Module<DogState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    dogs: [],
    total: 0,
  },
  actions: {
    async create(
      { commit, dispatch },
      payload: { body: DogCreateBody; service: DogService }
    ) {
      try {
        commit("setLoading", true);
        const { body, service } = payload;
        const dog = await service.createDog(body);
        commit("addDog", dog);
        commit("setLoading", false);
        dispatch(
          NotificationActions.ALERT,
          {
            status: AlertStatus.SUCCESS,
            message: "Successfully Created Service Dog",
          },
          { root: true }
        );
        return dog;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
    async addTraining(
      { state, commit, dispatch },
      payload: {
        id: string;
        body: DogTrainingCreateBody;
        service: DogService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { id, body, service } = payload;
        const dog = await service.createDogTraining(id, body);
        if (state && state.selected && state.selected.id === dog.id) {
          commit("setSelected", dog);
        }
        commit("setLoading", false);
        dispatch(
          NotificationActions.ALERT,
          {
            status: AlertStatus.SUCCESS,
            message: "Successfully Added Training Entry for Service Dog",
          },
          { root: true }
        );
        return dog;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
    async selectCurrentTrainer(
      { commit, dispatch },
      payload: { id: string; service: UserService }
    ) {
      try {
        commit("setLoading", true);
        const { id, service } = payload;
        const userFromApi = await service.getUser(id);
        if (userFromApi && userFromApi.isDogTrainer) {
          commit("setCurrentTrainer", userFromApi);
        }
        commit("setLoading", false);
        return userFromApi;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
    async update(
      { state, commit, dispatch },
      payload: {
        id: string;
        body: DogUpdateBody;
        service: DogService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { id, body, service } = payload;
        const dog = await service.updateDog(id, body);
        commit("addDog", dog);
        if (state && state.selected && state.selected.id === dog.id) {
          commit("setSelected", dog);
        }
        commit("setLoading", false);
        dispatch(
          NotificationActions.ALERT,
          {
            status: AlertStatus.SUCCESS,
            message: "Successfully Updated Service Dog",
          },
          { root: true }
        );
        return dog;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
    async deleteTraining(
      { state, commit, dispatch },
      payload: {
        id: number | string;
        trainingId: number | string;
        service: DogService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { id, trainingId, service } = payload;
        const dog = await service.deleteTraining(id, trainingId);
        commit("addDog", dog);
        if (state && state.selected && state.selected.id === dog.id) {
          commit("setSelected", dog);
        }
        commit("setLoading", false);
        dispatch(
          NotificationActions.ALERT,
          {
            status: AlertStatus.SUCCESS,
            message: "Successfully Deleted Training",
          },
          { root: true }
        );
        return dog;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
    async list(
      { commit, dispatch },
      payload: { service: DogService; take?: number; skip?: number }
    ) {
      try {
        commit("setLoading", true);
        const { service, take, skip } = payload;
        const { dogs, total } = await service.listDogs(
          take || 0, // defaulting to 0 to get all results until we need to paginate the table results based on size.  Just like pokemon, I want to "catch em all"
          skip || 0
        );
        commit("setDogs", dogs);
        commit("setTotal", total);
        commit("setLoading", false);
        return dogs;
      } catch (error) {
        if (process.env.NODE_ENV == "test") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
    async select(
      { commit, dispatch },
      payload: { id: string | number; service: DogService }
    ) {
      try {
        commit("setLoading", true);
        const { id, service } = payload;
        const userFromApi = await service.getDog(id);
        if (userFromApi) {
          commit("addDog", userFromApi);
          commit("setSelected", userFromApi);
        }
        commit("setLoading", false);
        return userFromApi;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
    async deleteDog(
      { commit, dispatch },
      payload: { service: DogService; id: string }
    ) {
      commit("setLoading", true);
      const { service, id } = payload;
      try {
        await service.deleteDog(id);
        commit("setLoading", false);
        dispatch(
          NotificationActions.ALERT,
          {
            status: AlertStatus.SUCCESS,
            message: "Successfully Deleted Service Dog",
          },
          { root: true }
        );
        return null;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
        return null;
      }
    },
    async reactivate(
      { commit, dispatch },
      payload: { service: DogService; id: string }
    ) {
      commit("setLoading", true);
      const { service, id } = payload;
      try {
        await service.reactivateDog(id);
        commit("setLoading", false);
        dispatch(
          NotificationActions.ALERT,
          {
            status: AlertStatus.SUCCESS,
            message: "Successfully Reactivated Service Dog",
          },
          { root: true }
        );
        return null;
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
        return null;
      }
    },

    async getFile(
      { commit, dispatch },
      payload: { service: DogService; id: number }
    ) {
      commit("setLoading", true);
      const { id, service } = payload;
      try {
        const url = await service.getDogFile(id);
        if (url) {
          commit("setLoading", false);
          return url;
        }

        commit("setLoading", false);
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
        return null;
      }
    },
  },
  mutations: {
    setLoading(state: DogState, loading: boolean) {
      Vue.set(state, "loading", loading);
    },
    addDog(state: DogState, dog: Dog) {
      const dogs = state.dogs.filter(
        (existingDog) => existingDog.id !== dog.id
      );
      dogs.push(dog);
      Vue.set(state, "dogs", dogs);
    },
    setDogs(state: DogState, dogs: Array<Dog>) {
      Vue.set(state, "dogs", dogs);
    },
    setTotal(state: DogState, total: number) {
      Vue.set(state, "total", total);
    },
    setSelected(state: DogState, dog: Dog) {
      Vue.set(state, "selected", dog);
    },
    setCurrentTrainer(state: DogState, trainer: User) {
      Vue.set(state, "currentTrainer", trainer);
    },
  },
  getters: {
    loading: (state) => state.loading,
    dogs: (state) => state.dogs,
    dogsSortedByName: (state) => {
      if (!state.dogs || !state.dogs.length) {
        return [];
      } else {
        return state.dogs
          .filter((dog) => !dog.deleted)
          .sort((a, b) => {
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();
            if (aName > bName) {
              return 1;
            }
            if (aName < bName) {
              return -1;
            }
            return 0;
          });
      }
    },
    total: (state) => state.total,
    selected: (state) => state.selected,
    currentTrainer: (state) => state.currentTrainer,
    currentTrainerTrainings: (state) => {
      const transformedTraining: DogTrainerTableDisplayBody[] = [];
      let newTraining: DogTrainerTableDisplayBody[];
      state.dogs.forEach((dog) => {
        dog.dogTrainings = (dog.dogTrainings || []).filter(
          (training) => training.user.id === (state.currentTrainer || {}).id
        );
        if ((dog.dogTrainings || []).length) {
          newTraining = dog.dogTrainings.map((training) => {
            return { ...training, dogId: dog.id, name: dog.name };
          });
          newTraining.forEach((training) => transformedTraining.push(training));
        }
      });
      return transformedTraining;
    },
    recertificationNeeded: (state) => {
      const filteredByCertificationDate: Dog[] = state.dogs.filter(
        (dog) =>
          dog.deleted === null &&
          dog.trainingCertExpires &&
          !dog.retired &&
          !dog.deceased &&
          new Date(
            getDate(dog.trainingCertExpires.toString()) as string
          ).getTime() <=
            new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000).getTime()
      );
      const sortedByCertificationDate: Dog[] = filteredByCertificationDate.sort(
        (a, b) => {
          const trainingCertExpiresDateA = a.trainingCertExpires
            ? new Date(
                getDate(a.trainingCertExpires.toString() as string) as string
              ).getTime()
            : new Date(new Date().setFullYear(new Date().getFullYear() + 10));
          const trainingCertExpiresDateB = b.trainingCertExpires
            ? new Date(
                getDate(b.trainingCertExpires.toString() as string) as string
              ).getTime()
            : new Date(new Date().setFullYear(new Date().getFullYear() + 10));
          if (trainingCertExpiresDateA > trainingCertExpiresDateB) {
            return 1;
          }
          if (trainingCertExpiresDateA < trainingCertExpiresDateB) {
            return -1;
          }
          return 0;
        }
      );
      return sortedByCertificationDate.slice(0, 5).map((dog) => ({
        ...dog,
        trainingCertExpirationInDays: dog.trainingCertExpires
          ? (new Date(
              getDate(dog.trainingCertExpires.toString() as string) as string
            ).getTime() -
              new Date().getTime()) /
            86400000
          : undefined,
        trainingCertExpirationInDaysAbsAndRnd: dog.trainingCertExpires
          ? Math.round(
              Math.abs(
                (new Date(
                  getDate(
                    dog.trainingCertExpires.toString() as string
                  ) as string
                ).getTime() -
                  new Date().getTime()) /
                  86400000
              )
            )
          : undefined,
      }));
    },
    totalDogsNeedingRecertifications: (state) => {
      const filteredByCertificationDate: Dog[] = state.dogs.filter(
        (dog) =>
          dog.deleted === null &&
          dog.trainingCertExpires &&
          !dog.retired &&
          !dog.deceased &&
          new Date(
            getDate(dog.trainingCertExpires.toString()) as string
          ).getTime() < new Date().getTime()
      );
      return (filteredByCertificationDate || []).length;
    },
    vaccinationNeeded: (state) => {
      const filteredByVaccinationDate: DogExt[] = (state.dogs as DogExt[]).filter(
        (dog) => {
          const vaccinationDates: number[] = [];
          const medical = dog.medical;
          if (!medical) {
            return false;
          }

          if (dog.deleted !== null || dog.retired || dog.deceased) {
            return false;
          }

          if (medical.bordetellaDue) {
            vaccinationDates.push(
              new Date(
                getDate(medical.bordetellaDue.toString()) as string
              ).getTime()
            );
          }
          if (medical.parvovirusDue) {
            vaccinationDates.push(
              new Date(
                getDate(medical.parvovirusDue.toString()) as string
              ).getTime()
            );
          }
          if (medical.dhpDue) {
            vaccinationDates.push(
              new Date(getDate(medical.dhpDue.toString()) as string).getTime()
            );
          }
          if (medical.leptospirosisDue) {
            vaccinationDates.push(
              new Date(
                getDate(medical.leptospirosisDue.toString()) as string
              ).getTime()
            );
          }
          if (medical.rabiesDue) {
            vaccinationDates.push(
              new Date(
                getDate(medical.rabiesDue.toString()) as string
              ).getTime()
            );
          }

          const oldest = vaccinationDates.length
            ? vaccinationDates.reduce(function (a, b) {
                return a < b ? a : b;
              })
            : undefined;
          dog.oldestVaccinationInDays = oldest
            ? (oldest - new Date().getTime()) / 86400000
            : undefined;
          dog.oldestVaccinationInDaysAbsAndRnd = oldest
            ? Math.round(Math.abs((oldest - new Date().getTime()) / 86400000))
            : undefined;
          return (
            oldest &&
            oldest <=
              new Date(
                new Date().getTime() + 30 * 24 * 60 * 60 * 1000
              ).getTime()
          );
        }
      );
      const sortedByVaccinationDate: DogExt[] = filteredByVaccinationDate.sort(
        (a, b) => {
          const oldestVaccinationInDaysA = a.oldestVaccinationInDays || 10000;
          const oldestVaccinationInDaysB = b.oldestVaccinationInDays || 10000;
          if (oldestVaccinationInDaysA > oldestVaccinationInDaysB) {
            return 1;
          }
          if (oldestVaccinationInDaysA < oldestVaccinationInDaysB) {
            return -1;
          }
          return 0;
        }
      );
      return sortedByVaccinationDate.slice(0, 5);
    },
    totalDogsNeedingVaccinations: (state) => {
      const filteredByVaccinationDate: Dog[] = state.dogs.filter((dog) => {
        const medical = dog.medical;
        if (!medical) {
          return false;
        }

        if (dog.deleted !== null || dog.retired || dog.deceased) {
          return false;
        }

        return (
          (medical.bordetellaDue &&
            new Date(
              getDate(medical.bordetellaDue.toString()) as string
            ).getTime() < new Date().getTime()) ||
          (medical.parvovirusDue &&
            new Date(
              getDate(medical.parvovirusDue.toString()) as string
            ).getTime() < new Date().getTime()) ||
          (medical.dhpDue &&
            new Date(getDate(medical.dhpDue.toString()) as string).getTime() <
              new Date().getTime()) ||
          (medical.leptospirosisDue &&
            new Date(
              getDate(medical.leptospirosisDue.toString()) as string
            ).getTime() < new Date().getTime()) ||
          (medical.rabiesDue &&
            new Date(
              getDate(medical.rabiesDue.toString()) as string
            ).getTime() < new Date().getTime())
        );
      });
      return (filteredByVaccinationDate || []).length;
    },
    totalDogsInTraining: (state) => {
      const filteredByInTraining: Dog[] = state.dogs.filter(
        (dog) =>
          dog.trainingStatus !== DOG_TRAINING_STATUS_VALUES.FAILED &&
          dog.trainingStatus !== DOG_TRAINING_STATUS_VALUES.PASSED &&
          dog.deleted === null &&
          !dog.retired &&
          !dog.deceased &&
          (dog.dogTrainings || []).length
      );
      return (filteredByInTraining || []).length;
    },
    totalActive: (state) => {
      const filteredByActive: Dog[] = state.dogs.filter(
        (dog) => dog.deleted === null
      );
      return (filteredByActive || []).length;
    },
  },
};

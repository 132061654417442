import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#416b3b",
        secondary: "#000000",
        accent: "#ACE1AF",
        error: "#d4110f",
        warning: "#fbb000",
        info: "#ccc5b9",
        success: "#92ab8f",
      },
    },
  },
});

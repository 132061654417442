import { DogTrainingService } from "@/services/interfaces";
import { provide, inject } from "@vue/composition-api";

export const dogTrainingServiceSymbol = Symbol();

export function provideDogTrainingService(store: DogTrainingService) {
  return provide(dogTrainingServiceSymbol, store);
}

export function useDogTrainingService() {
  const dogTrainingService = inject<DogTrainingService>(
    dogTrainingServiceSymbol
  );
  if (!dogTrainingService) {
    throw Error("No Training Hours Service to Inject!!");
  }
  return dogTrainingService;
}

import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { userRoutes } from "./userRoutes";
import { applicantRoutes } from "./applicantRoutes";
import { dogRoutes } from "@/router/dogRoutes";
import { dogTrainingRoutes } from "@/router/dogTrainingRoutes";
import store from "@/store";
import { SessionActions, SessionGetters } from "@/store/session";
import { UserService } from "@/services/UserService";
import axios from "@/axios";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "Dashboard",
    meta: {
      title: "Dashboard",
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
  },
  {
    path: "/reports",
    name: "ReportList",
    meta: {
      title: "Reports",
    },
    component: () =>
      import(
        /* webpackChunkName: "report-list" */ "../views/reports/Reports.vue"
      ),
  },

  ...userRoutes,
  ...applicantRoutes,
  ...dogRoutes,
  ...dogTrainingRoutes,

  {
    path: "/404",
    alias: "*",
    name: "NotFound",
    meta: {
      title: "Page Not Found",
    },
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach(async (to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.

  //determine if user is signed in
  if (!store.getters[SessionGetters.USER]) {
    await store.dispatch(SessionActions.LOAD_USER, {
      service: new UserService(axios),
    });
  }

  //redirect to default path if permissions don't match required role
  if (
    to.name?.toLocaleLowerCase().startsWith("user") &&
    !store.getters[SessionGetters.IS_SITE_ADMIN_OR_ORGANIZATION_ADMIN]
  ) {
    return next({ path: "/" });
  } else if (
    (to.name?.toLocaleLowerCase().startsWith("dog") ||
      to.name?.toLocaleLowerCase().startsWith("applicant") ||
      to.name?.toLocaleLowerCase().startsWith("report")) &&
    !store.getters[SessionGetters.IS_STAFF_OR_HIGHER]
  ) {
    return next({ path: "/" });
  } else if (
    to.name?.toLocaleLowerCase().startsWith("train") &&
    !(
      store.getters[SessionGetters.IS_STAFF_OR_HIGHER] ||
      store.getters[SessionGetters.IS_DOG_TRAINER]
    )
  ) {
    return next({ path: "/" });
  }

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.  Otherwise default.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title + " - GYSSD";
  } else {
    document.title = "GYSSD";
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el && el.parentNode && el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef: { [x: string]: string }) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag: HTMLMetaElement) => document.head.appendChild(tag));

  return next();
});

export default router;

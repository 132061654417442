import { ApplicantService as IApplicantService } from "./interfaces";
import { Applicant, ApplicantCreateBody, ApplicantUpdateBody } from "@/store";
import { AxiosInstance } from "axios";
import { APPLICANT_ATTACHMENT_TYPES } from "@/store/constants";

export class ApplicantService implements IApplicantService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getApplicant(id: string): Promise<Applicant> {
    const response = await this.http.get(`${this.apiUrl}/applicants/${id}`);
    return response.data.entity;
  }

  async createApplicant(body: ApplicantCreateBody): Promise<Applicant> {
    const form = new FormData();
    if (body.provider) {
      body.provider = JSON.stringify(body.provider);
    }
    if (body.sponsor) {
      body.sponsor = JSON.stringify(body.sponsor);
    }
    if (body.services) {
      body.services = JSON.stringify(body.services);
    }
    if (body.notes) {
      body.notes = JSON.stringify(body.notes);
    }
    if (body.emergencyContact) {
      body.emergencyContact = JSON.stringify(body.emergencyContact);
    }
    if (body.additionalProviders) {
      if (
        Array.isArray(body.additionalProviders) &&
        body.additionalProviders.length > 0
      ) {
        body.additionalProviders = body.additionalProviders.filter((p) => !!p);
      }
      body.additionalProviders = JSON.stringify(body.additionalProviders);
    }

    Object.entries(body).forEach(([key, value]) => {
      if (key !== "serviceEmploymentFiles" && key !== "medicalFiles")
        form.append(key, value);
    });

    if (body.serviceEmploymentFiles) {
      body.serviceEmploymentFiles.forEach((value) => {
        form.append(APPLICANT_ATTACHMENT_TYPES.SERVICE, value);
      });
    }

    if (body.medicalFiles) {
      body.medicalFiles.forEach((value) => {
        form.append(APPLICANT_ATTACHMENT_TYPES.MEDICAL, value);
      });
    }

    const response = await this.http.post(`${this.apiUrl}/applicants`, form, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.entity;
  }

  async updateApplicant(
    id: number | string,
    body: ApplicantUpdateBody
  ): Promise<Applicant> {
    if (body.deletedFiles) {
      body.deletedFiles = JSON.stringify(body.deletedFiles);
    }
    if (body.services) {
      body.services = JSON.stringify(body.services);
    }
    if (body.notes) {
      body.notes = JSON.stringify(body.notes);
    }
    if (body.provider) {
      body.provider = JSON.stringify(body.provider);
    }
    if (body.additionalProviders) {
      if (
        Array.isArray(body.additionalProviders) &&
        body.additionalProviders.length > 0
      ) {
        body.additionalProviders = body.additionalProviders.filter((p) => !!p);
      }
      body.additionalProviders = JSON.stringify(body.additionalProviders);
    }

    if (body.sponsor) {
      body.sponsor = JSON.stringify(body.sponsor);
    }

    if (body.emergencyContact) {
      body.emergencyContact = JSON.stringify(body.emergencyContact);
    }

    const form = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (key !== "serviceEmploymentFiles" && key !== "medicalFiles")
        form.append(key, value);
    });

    if (body.serviceEmploymentFiles) {
      body.serviceEmploymentFiles.forEach((value) => {
        form.append(APPLICANT_ATTACHMENT_TYPES.SERVICE, value);
      });
    }

    if (body.medicalFiles) {
      body.medicalFiles.forEach((value) => {
        form.append(APPLICANT_ATTACHMENT_TYPES.MEDICAL, value);
      });
    }

    const response = await this.http.patch(
      `${this.apiUrl}/applicants/${id}`,
      form,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data.entity;
  }

  async listApplicants(
    take: number,
    skip: number
  ): Promise<{ applicants: Applicant[]; total: number }> {
    const response = await this.http.get(`${this.apiUrl}/applicants`, {
      params: {
        take,
        skip,
      },
    });
    return {
      applicants: response.data.entities,
      total: response.data.total,
    };
  }

  async deleteApplicant(id: number | string): Promise<number> {
    const response = await this.http.delete(`${this.apiUrl}/applicants/${id}`);
    return response.status;
  }

  async reactivateApplicant(id: string): Promise<number> {
    const response = await this.http.patch(
      `${this.apiUrl}/applicants/${id}/reactivate`
    );
    return response.status;
  }

  async getAppplicantFile(id: number): Promise<string> {
    const response = await this.http.get(
      `${this.apiUrl}/files/applicant/${id}`
    );
    return response.data.entity;
  }
}

import { ReadOnlyEntityService } from "@/services/interfaces";
import { Service } from "@/store";
import { provide, inject } from "@vue/composition-api";

export const serviceTypeServiceSymbol = Symbol();

export function provideServiceTypeService(
  service: ReadOnlyEntityService<Service>
) {
  return provide(serviceTypeServiceSymbol, service);
}

export function useServiceTypeService(): ReadOnlyEntityService<Service> {
  const service = inject<ReadOnlyEntityService<Service>>(
    serviceTypeServiceSymbol
  );
  if (!service) {
    throw Error("No Service Service to Inject!");
  }
  return service;
}

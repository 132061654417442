import { RouteConfig } from "vue-router";

export const dogTrainingRoutes: RouteConfig[] = [
  {
    path: "/dog-trainings/create",
    name: "TrainingDogCreate",
    component: () =>
      import(
        /* webpackChunkName: "dog-training-create" */ "@/views/dog-training-entries/Create.vue"
      ),
  },
  {
    path: "/dog-trainings/trainer/:id",
    name: "TrainerDogList",
    component: () =>
      import(
        /* webpackChunkName: "dog-trainer-list" */ "@/views/dog-training-entries/List.vue"
      ),
  },
];

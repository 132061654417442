import { ReadOnlyEntityService, PaginatedResponse } from "./interfaces";
import { Role } from "@/store";
import { AxiosInstance } from "axios";

export class RoleService implements ReadOnlyEntityService<Role> {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  public async list(): Promise<PaginatedResponse<Role>> {
    const response = await this.http.get(`${this.apiUrl}/roles`);
    return {
      entities: response.data.entities,
      total: response.data.total,
    };
  }

  get(id: number): Promise<Role> {
    throw new Error("Method not implemented." + id);
  }
}

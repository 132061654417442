import { ReadOnlyEntityService } from "@/services/interfaces";
import { Provider } from "@/store";
import { provide, inject } from "@vue/composition-api";

export const providerServiceSymbol = Symbol();

export function provideProviderService(
  service: ReadOnlyEntityService<Provider>
) {
  return provide(providerServiceSymbol, service);
}

export function useProviderService(): ReadOnlyEntityService<Provider> {
  const service = inject<ReadOnlyEntityService<Provider>>(
    providerServiceSymbol
  );
  if (!service) {
    throw Error("No Provider Service to Inject!");
  }
  return service;
}

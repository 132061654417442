import { RouteConfig } from "vue-router";

export const dogRoutes: RouteConfig[] = [
  {
    path: "/dogs/create",
    name: "DogCreate",
    meta: {
      title: "Create Support Dog",
    },
    component: () =>
      import(/* webpackChunkName: "dog-create" */ "@/views/dogs/Create.vue"),
  },
  {
    path: "/dogs",
    name: "DogList",
    meta: {
      title: "Support Dogs",
    },
    component: () =>
      import(/* webpackChunkName: "dog-list" */ "@/views/dogs/List.vue"),
  },
  {
    path: "/dogs/:id",
    name: "DogUpdate",
    meta: {
      title: "Update Support Dog",
    },
    component: () =>
      import(/* webpackChunkName: "dog-update" */ "@/views/dogs/Update.vue"),
  },
  {
    path: "/dogs/details/:id",
    name: "DogDetail",
    meta: {
      title: "Support Dog Detail",
    },
    component: () =>
      import(/* webpackChunkName: "dog-detail" */ "@/views/dogs/Details.vue"),
  },
];

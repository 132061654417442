import { Module } from "vuex";
import { Alert, RootState } from "@/store";
import Vue from "vue";

export interface NotificationModuleState {
  loading: boolean;
  alerts: Alert[];
}

export enum NotificationActions {
  ALERT = "NotificationModule/alert",
  CLEAR_ALERT = "NotificationModule/clearAlert",
}

export enum NotificationMutations {
  ADD_ALERT = "NotificationModule/addAlert",
  REMOVE_ALERT = "NotificationModule/removeAlert",
}

export enum NotificationGetters {
  LOADING = "NotificationModule/loading",
  ALERTS = "NotificationModule/alerts",
  LATEST_ALERT = "NotificationModule/latestAlert",
}

export const NotificationModule: Module<NotificationModuleState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    alerts: [],
  },
  actions: {
    alert({ commit }, payload: Alert) {
      commit("addAlert", payload);
    },
    clearAlert({ commit }, payload: Alert) {
      commit("removeAlert", payload);
    },
  },
  mutations: {
    addAlert(state: NotificationModuleState, payload: Alert) {
      const alerts = state.alerts;
      Vue.set(state, "alerts", [...alerts, payload]);
    },
    removeAlert(state: NotificationModuleState, payload: Alert) {
      const alerts = state.alerts;
      const firstIdx = alerts.findIndex(
        (alert) =>
          alert.message === payload.message && alert.status === payload.status
      );
      if (firstIdx > -1) {
        alerts.splice(firstIdx, 1);
      }
      Vue.set(state, "alerts", alerts);
    },
  },
  getters: {
    loading: (state) => state.loading,
    alerts: (state) => state.alerts,
    latestAlert: (state) => (state.alerts.length ? state.alerts[0] : null),
  },
};

import { ReadOnlyEntityService, PaginatedResponse } from "./interfaces";
import { Provider } from "@/store";
import { AxiosInstance } from "axios";

export class ProviderService implements ReadOnlyEntityService<Provider> {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  public async list(): Promise<PaginatedResponse<Provider>> {
    const response = await this.http.get(`${this.apiUrl}/providers`);
    return {
      entities: response.data.entities,
      total: response.data.total,
    };
  }

  async get(id: string): Promise<Provider> {
    const response = await this.http.get(`${this.apiUrl}/providers/${id}`);
    return response.data.entity;
  }
}

import { DogService } from "@/services/interfaces";
import { provide, inject } from "@vue/composition-api";

export const dogServiceSymbol = Symbol();

export function provideDogService(store: DogService) {
  return provide(dogServiceSymbol, store);
}

export function useDogService() {
  const dogService = inject<DogService>(dogServiceSymbol);
  if (!dogService) {
    throw Error("No Dog Service to Inject!!");
  }
  return dogService;
}

import { UserService } from "@/services/interfaces";
import { provide, inject } from "@vue/composition-api";

export const userServiceSymbol = Symbol();

export function provideUserService(store: UserService) {
  return provide(userServiceSymbol, store);
}

export function useUserService() {
  const userService = inject<UserService>(userServiceSymbol);
  if (!userService) {
    throw Error("No User Service to Inject!!");
  }
  return userService;
}

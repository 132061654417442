import { UserService as IUserService } from "./interfaces";
import { User, UserCreateBody, UserUpdateBody } from "@/store";
import { AxiosInstance } from "axios";

export class UserService implements IUserService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getUser(id: string): Promise<User> {
    const response = await this.http.get(`${this.apiUrl}/users/${id}`);
    return response.data.entity;
  }

  async createUser(body: UserCreateBody): Promise<User> {
    const response = await this.http.post(`${this.apiUrl}/users`, body);
    return response.data.entity;
  }

  async listUsers(
    take: number,
    skip: number
  ): Promise<{ users: User[]; total: number }> {
    const response = await this.http.get(`${this.apiUrl}/users`, {
      params: {
        take,
        skip,
      },
    });
    return {
      users: response.data.entities,
      total: response.data.total,
    };
  }

  async updateUser(id: string, body: UserUpdateBody): Promise<User> {
    const response = await this.http.patch(`${this.apiUrl}/users/${id}`, body);
    return response.data.entity;
  }

  async deleteUser(id: string): Promise<number> {
    const response = await this.http.delete(`${this.apiUrl}/users/${id}`);
    return response.status;
  }

  async reactivateUser(id: string): Promise<number> {
    const response = await this.http.patch(
      `${this.apiUrl}/users/${id}/reactivate`
    );
    return response.status;
  }

  async resendUserPassword(id: string): Promise<boolean> {
    const response = await this.http.post(
      `${this.apiUrl}/users/${id}/resend-password`
    );
    return response.data.success;
  }
}


































































































































import {
  defineComponent,
  computed,
  ref,
  onUnmounted,
  watch,
} from "@vue/composition-api";
import { useStore } from "@/composables/providers/use-store";
import { useInterfaceNavigation } from "@/composables/helpers/use-interface-navigation";
import { SessionActions, SessionGetters } from "../../store/session";
import { User } from "@/store";

export default defineComponent({
  name: "Navigation",
  components: {},
  setup() {
    const store = useStore();
    const { drawer } = useInterfaceNavigation(store);

    const isSiteOrOrganizationAdmin = computed(
      () => store.getters[SessionGetters.IS_SITE_ADMIN_OR_ORGANIZATION_ADMIN]
    );
    const isStaffOrHigher = computed(
      () => store.getters[SessionGetters.IS_STAFF_OR_HIGHER]
    );
    const isDogTrainer = computed(
      () => store.getters[SessionGetters.IS_DOG_TRAINER]
    );

    const mini = ref<boolean>(false);
    const windowWidth = ref<number>(window.outerWidth);
    const locked = ref<boolean>(false);
    const displayLock = ref<boolean>(false);

    const user = computed<User>(() => store.getters[SessionGetters.USER]);
    const firstName = computed(() => (user.value ? user.value.firstName : ""));
    const lastName = computed(() => (user.value ? user.value.lastName : ""));

    const role = computed(() =>
      user.value && user.value.role ? user.value.role.name : ""
    );

    const initials = computed<string>(() => {
      if (user.value) {
        return `${user.value.firstName[0]}${user.value.lastName[0]}`;
      }
      return "";
    });

    async function signOut() {
      await store.dispatch(SessionActions.SIGN_OUT);
    }

    function handleResize() {
      if (windowWidth.value !== window.outerWidth && !locked.value) {
        windowWidth.value = window.outerWidth;
        if (windowWidth.value < 1024) {
          mini.value = true;
        } else {
          mini.value = false;
        }
      }
    }

    watch(mini, () => {
      displayLock.value = true;
      setTimeout(() => (displayLock.value = false), 3000);
    });

    window.addEventListener("resize", handleResize);

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    if (windowWidth.value < 1024) {
      mini.value = true;
    }

    return {
      drawer,
      firstName,
      lastName,
      initials,
      isDogTrainer,
      isStaffOrHigher,
      isSiteOrOrganizationAdmin,
      mini,
      role,
      signOut,
      displayLock,
      locked,
    };
  },
});

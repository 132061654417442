import { Module } from "vuex";
import { RootState, Service } from "@/store";
import { ReadOnlyEntityService } from "@/services/interfaces";
import Vue from "vue";
import { ServiceTypeService } from "@/services/ServiceTypeService";
import { NotificationActions } from "@/store/notification";
// DO NOT MOVE THIS OR ELSE
import { AlertStatus } from "@/store/interfaces";

export interface ServiceState {
  loading: boolean;
  services: Array<Service>;
}

export enum ServiceActions {
  LIST = "ServiceTypeModule/list",
}

export enum ServiceMutations {
  SET_LOADING = "ServiceTypeModule/setLoading",
  SET_SERVICES = "ServiceTypeModule/setServices",
}

export enum ServiceGetters {
  LOADING = "ServiceTypeModule/loading",
  SERVICES = "ServiceTypeModule/services",
  MILITARY_SERVICES = "ServiceTypeModule/militaryServices",
  RESPONDER_SERVICES = "ServiceTypeModule/responderServices",
}

export const ServiceTypeModule: Module<ServiceState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    services: [],
  },
  actions: {
    async list(
      { commit, state, dispatch },
      payload: {
        service: ReadOnlyEntityService<ServiceTypeService>;
        force?: boolean;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service, force } = payload;
        if (state.services.length < 1 || force === true) {
          const { entities } = await service.list();
          commit("setServices", entities);
        }
        commit("setLoading", false);
      } catch (error) {
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        dispatch(
          NotificationActions.ALERT,
          { status: AlertStatus.ERROR, message: error.data },
          { root: true }
        );
        commit("setLoading", false);
      }
    },
  },
  mutations: {
    setLoading(state: ServiceState, loading: boolean) {
      Vue.set(state, "loading", loading);
    },
    setServices(state: ServiceState, services: Array<Service>) {
      Vue.set(state, "services", services);
    },
  },
  getters: {
    loading: (state) => state.loading,
    services: (state) => state.services,
    militaryServices: (state) =>
      state.services.filter((service) => service.type === "military"),
    responderServices: (state) =>
      state.services.filter((service) => service.type === "responder"),
  },
};

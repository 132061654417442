export const STATE_LIST = [
  { value: "AK", text: "Alaska" },
  { value: "AL", text: "Alabama" },
  { value: "AR", text: "Arkansas" },
  { value: "AS", text: "American Samoa" },
  { value: "AZ", text: "Arizona" },
  { value: "CA", text: "California" },
  { value: "CO", text: "Colorado" },
  { value: "CT", text: "Connecticut" },
  { value: "DC", text: "District of Columbia" },
  { value: "DE", text: "Delaware" },
  { value: "FL", text: "Florida" },
  { value: "GA", text: "Georgia" },
  { value: "GU", text: "Guam" },
  { value: "HI", text: "Hawaii" },
  { value: "IA", text: "Iowa" },
  { value: "ID", text: "Idaho" },
  { value: "IL", text: "Illinois" },
  { value: "IN", text: "Indiana" },
  { value: "KS", text: "Kansas" },
  { value: "KY", text: "Kentucky" },
  { value: "LA", text: "Louisiana" },
  { value: "MA", text: "Massachusetts" },
  { value: "MD", text: "Maryland" },
  { value: "ME", text: "Maine" },
  { value: "MI", text: "Michigan" },
  { value: "MN", text: "Minnesota" },
  { value: "MO", text: "Missouri" },
  { value: "MS", text: "Mississippi" },
  { value: "MT", text: "Montana" },
  { value: "NC", text: "North Carolina" },
  { value: "ND", text: "North Dakota" },
  { value: "NE", text: "Nebraska" },
  { value: "NH", text: "New Hampshire" },
  { value: "NJ", text: "New Jersey" },
  { value: "NM", text: "New Mexico" },
  { value: "NV", text: "Nevada" },
  { value: "NY", text: "New York" },
  { value: "OH", text: "Ohio" },
  { value: "OK", text: "Oklahoma" },
  { value: "OR", text: "Oregon" },
  { value: "PA", text: "Pennsylvania" },
  { value: "PR", text: "Puerto Rico" },
  { value: "RI", text: "Rhode Island" },
  { value: "SC", text: "South Carolina" },
  { value: "SD", text: "South Dakota" },
  { value: "TN", text: "Tennessee" },
  { value: "TX", text: "Texas" },
  { value: "UT", text: "Utah" },
  { value: "VA", text: "Virginia" },
  { value: "VI", text: "Virgin Islands" },
  { value: "VT", text: "Vermont" },
  { value: "WA", text: "Washington" },
  { value: "WI", text: "Wisconsin" },
  { value: "WV", text: "West Virginia" },
  { value: "WY", text: "Wyoming" },
];

export const SPONSOR_RELATIONSHIP = [
  { value: 1, text: "Family" },
  { value: 2, text: "Coworker" },
  { value: 3, text: "Friend" },
  { value: 4, text: "Pastor" },
  { value: 5, text: "Neighbor" },
  { value: 6, text: "Other" },
];

export const ACQUISITION_TYPE = [
  { value: 1, text: "Purchased" },
  { value: 2, text: "Donated" },
  { value: 3, text: "Adopted" },
];

export const DOG_TRAINING_STATUS = [
  { value: 1, text: "Passed" },
  { value: 2, text: "Failed" },
];

export enum DOG_TRAINING_STATUS_VALUES {
  PASSED = 1,
  FAILED,
}

export enum DOG_TRAINING_STATUS_DISPLAY_TEXT {
  PASSED = "Passed Training",
  FAILED = "Failed Training",
  IN_TRAINING = "In Training",
}

export enum DOG_STATUS_DISPLAY_TEXT {
  ADOPTED = "Adopted",
  PURCHASED = "Purchased",
  DONATED = "Donated",
  PASSED = "Passed Training",
  FAILED = "Failed Training",
  IN_TRAINING = "In Training",
  PLACED = "Placed with Recipient",
  PLACED_ADOPTION = "Placed by Adoption",
  ACQUIRED = "Acquired",
}

export const CONTACT_RELATIONSHIP = [
  { value: 1, text: "Family" },
  { value: 2, text: "Coworker" },
  { value: 3, text: "Friend" },
  { value: 4, text: "Pastor" },
  { value: 5, text: "Neighbor" },
  { value: 6, text: "Other" },
];

export const SPECIALTIES = [
  { value: 1, text: "Psychiatrist" },
  { value: 2, text: "Medical Doctor" },
  { value: 3, text: "Therapist" },
  { value: 4, text: "Psychologist" },
  { value: 5, text: "Social Worker" },
  { value: 6, text: "Other" },
];

export const APPLICANT_STATUS_LIST = [
  { value: 1, text: "Under Review" },
  { value: 2, text: "Applicant Interview" },
  { value: 3, text: "Sponsor Interview" },
  { value: 4, text: "Physician Interview" },
  { value: 5, text: "Background Investigation" },
  { value: 6, text: "Home Inspection" },
  { value: 7, text: "Approval" },
  { value: 8, text: "Rejection" },
];

export enum APPLICANT_STATUS {
  UNKNOWN,
  UNDER_REVIEW,
  APPLICANT_INTERVIEW,
  SPONSOR_INTERVIEW,
  PHYSICIAN_INTERVIEW,
  BACKGROUND_INVESTIGATION,
  HOME_INSPECTION,
  APPROVAL,
  REJECTION,
}

export enum APPLICANT_STATUS_DISPLAY_TEXT {
  UNKNOWN = "Unknown",
  UNDER_REVIEW = "Under Review",
  APPLICANT_INTERVIEW = "Applicant Interview",
  SPONSOR_INTERVIEW = "Sponsor Interview",
  PHYSICIAN_INTERVIEW = "Physician Interview",
  BACKGROUND_INVESTIGATION = "Background Investigation",
  HOME_INSPECTION = "Home Inspection",
  APPROVAL = "Approval",
  REJECTION = "Rejection",
}

export enum USER_ROLES {
  SITE_ADMIN = 1,
  ORGANIZATION_ADMIN,
  STAFF,
  DOG_TRAINER,
}

export enum USER_ROLES_DISPLAY_TEXT {
  SITE_ADMIN = "Site Admin",
  ORGANIZATION_ADMIN = "Organization Admin",
  STAFF = "Staff",
  DOG_TRAINER = "Dog Trainer",
}

export enum APPLICANT_ATTACHMENT_TYPES {
  APPLICATION = "applicationFile",
  HOME_VISIT = "homeVisitFile",
  MEDICAL = "medicalFile",
  SERVICE = "serviceEmploymentFile",
}

export enum DOG_ATTACHMENT_TYPES {
  ACQUISITION = "acquisitionFile",
  ADOPTION = "adoptionFile",
  MEDICAL_NOTES = "medicalNotesFile",
  MEDICAL_RECORDS = "medicalRecordsFile",
}

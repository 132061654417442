import Vue from "vue";
import Vuex from "vuex";
import { SessionModule } from "@/store/session";
import { UserModule } from "@/store/user";
import { RoleModule } from "@/store/role";
import { ApplicantModule } from "@/store/applicant";
import { DogModule } from "@/store/dog";
import { ProviderModule } from "@/store/provider";
import { ServiceTypeModule } from "@/store/service-type";
import { NotificationModule } from "@/store/notification";

export * from "./interfaces";

Vue.use(Vuex);

export interface RootState {
  version: string;
  loading: boolean;
}

export enum RootMutations {
  SET_LOADING = "setLoading",
}

export enum RootGetters {
  LOADING = "loading",
}

export enum RootActions {}

export default new Vuex.Store<RootState>({
  modules: {
    SessionModule,
    ServiceTypeModule,
    UserModule,
    RoleModule,
    ApplicantModule,
    DogModule,
    NotificationModule,
    ProviderModule,
  },
  state: {
    version: "0.0.1",
    loading: false,
  },
  actions: {},
  mutations: {
    setLoading(state, payload?: boolean) {
      state.loading = !!payload;
    },
  },
  getters: {
    loading: (state) => state.loading,
  },
});

import { RouteConfig } from "vue-router";

export const userRoutes: RouteConfig[] = [
  {
    path: "/users/create",
    name: "UserCreate",
    meta: {
      title: "Create User",
    },
    component: () =>
      import(/* webpackChunkName: "user-create" */ "@/views/users/Create.vue"),
  },
  {
    path: "/users",
    name: "UserList",
    meta: {
      title: "Users",
    },
    component: () =>
      import(/* webpackChunkName: "user-list" */ "@/views/users/List.vue"),
  },
  {
    path: "/users/:id",
    name: "UserUpdate",
    meta: {
      title: "Update User",
    },
    component: () =>
      import(/* webpackChunkName: "user-update" */ "@/views/users/Update.vue"),
  },
];
